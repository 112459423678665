@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    font-family: 'Montserrat', sans-serif;
}

header h1 {
  color: #000000;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
}

@keyframes glow {
    0% { text-shadow: 0 0 5px #0000ff, 0 0 10px #0000ff, 0 0 15px #0000ff, 0 0 20px #0000ff, 0 0 30px #0000ff, 0 0 40px #0000ff; }
    100% { text-shadow: 0 0 10px #0000ff, 0 0 20px #0000ff, 0 0 30px #0000ff, 0 0 40px #0000ff, 0 0 50px #0000ff, 0 0 60px #0000ff; }
}

.header nav ul {
    list-style: none;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    gap: 20px;
}
  
.header nav ul li {
    transition: color 0.3s ease;
}
  
.header nav ul li a {
    color: #333;
    text-decoration: none;
}
  
.header nav ul li a:hover {
    color: #007bff;
}
  
.header nav button {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
}
  
.header nav .nav-links {
    display: flex;
}

@media (max-width: 768px) {
    .header nav button {
        display: block;
    }
  
    .header nav .nav-links {
        display: none;
        flex-direction: column;
        position: absolute;
        background: #f8f9fa;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        width: auto;
        right: 10px; /* Adjusted to give space from the right edge */
        top: 100%;
        padding: 10px; /* Add padding for better spacing inside the dropdown */
    }
  
    .header nav .nav-links.open {
        display: flex;
    }
}
