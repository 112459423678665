.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Ensures all child elements are centered */
    height: 100vh; /* Full height of the viewport */
    background: #ffffff;
    font-family: 'Montserrat', sans-serif;
    padding: 20px; /* Add padding for better mobile display */
    text-align: center; /* Ensures text and other elements are centered */
}

.contact h1, .contact p {
    color: #000; /* Change text color to black for better visibility */
    font-size: 24px;
    margin-bottom: 20px; /* Add space between the text and buttons */
}

.contact p {
    font-size: 18px; /* Match the font size of descriptions */
    line-height: 1.5;
}

.contact-button {
    padding: 15px 20px;
    font-size: 18px;
    background-color: #5dbcd2; /* A more vibrant blue */
    color: #ffffff; /* White text for better contrast */
    border: none;
    border-radius: 8px; /* Add slight rounding for smoother look */
    cursor: pointer; /* Change cursor to pointer for clickable buttons */
    margin: 10px 0;
    width: 250px; /* Fixed width for all buttons */
    transition: transform 0.3s, background-color 0.3s; /* Add smooth transition for hover effect */
    align-self: center; /* Ensures the button stays centered within the flex container */
}

/* Add hover effect for buttons */
.contact-button:hover {
    background-color: #3fa6bb; /* Darker blue on hover */
    transform: scale(1.05); /* Slight scaling effect */
}

@media (max-width: 600px) {
    .contact {
        padding: 10px;
    }

    .contact h1, .contact p {
        font-size: 20px; /* Adjust for mobile readability */
    }

    .contact-button {
        width: 90%; /* Make buttons responsive on small screens */
    }
}
