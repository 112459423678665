/* Import Montserrat font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Footer Container */
.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0; /* Reduced padding to decrease height */
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    font-family: 'Montserrat', sans-serif;
}

/* Footer Paragraphs */
.footer p {
    color: #000000;
    font-size: 16px; /* Decreased font size for a more compact appearance */
    margin: 5px 0; /* Added margin for better spacing */
    text-align: center; /* Center-align text for better appearance on mobile */
}

/* Footer Links */
.footer a {
    color: #000000; /* Ensures links are styled properly */
    text-decoration: none;
}

/* Footer Links Hover Effect */
.footer a:hover {
    text-decoration: underline; /* Optional hover effect for links */
}

/* Suggestion Button Styling */
.footer .suggestion-button {
    display: inline-block; /* Allows padding and margin to take effect */
    padding: 10px 20px;
    background-color: #000000;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px 0; /* Adds vertical spacing */
    text-align: center; /* Centers text within the button */
    font-size: 16px; /* Ensures font size consistency */
    text-decoration: none; /* Removes underline if using <a> tag */
}

/* Suggestion Button Hover Effect */
.footer .suggestion-button:hover {
    background-color: #333333;
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
    .footer {
        padding: 8px 0; /* Further reduce padding on smaller screens */
    }

    .footer p {
        font-size: 14px; /* Slightly smaller font size on mobile */
        margin: 4px 0; /* Adjusted margin for mobile */
    }

    .footer .suggestion-button {
        width: 80%; /* Make button take up 80% of the footer's width */
        max-width: 300px; /* Optional: set a max width to prevent excessive stretching */
        padding: 10px; /* Adjust padding for better fit */
        font-size: 15px; /* Slightly adjust font size for mobile */
    }
}

/* Additional Responsive Adjustments for Very Small Screens */
@media (max-width: 480px) {
    .footer {
        padding: 6px 0; /* Further reduce padding */
    }

    .footer p {
        font-size: 13px; /* Further decrease font size */
        margin: 3px 0; /* Further adjust margin */
    }

    .footer .suggestion-button {
        width: 90%; /* Increase width to utilize available space */
        max-width: none; /* Remove max-width restriction */
        padding: 8px; /* Adjust padding */
        font-size: 14px; /* Adjust font size */
    }
}
