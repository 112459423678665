@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.video-section {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #ffffff;
    font-family: 'Montserrat', sans-serif;
    padding: 20px;
}

.video-section h1 {
    width: 100%;
    text-align: center;
    color: #000000; /* Ensure consistent color */
    font-family: 'Montserrat', sans-serif;
}

.video-container {
    width: 45%;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-container iframe {
    width: 100%;
    height: 315px; /* Set fixed height for better desktop display */
}

.video-details {
    margin-top: 10px;
    text-align: left;
}

.video-details p {
    margin: 5px 0;
}

@media (max-width: 600px) {
    .video-container {
        width: 100%;
    }

    .video-container iframe {
        height: auto; /* Adjust height to be flexible on mobile */
    }

    .video-details {
        text-align: center;
    }
}
