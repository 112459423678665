/* Gallery.css */

/* Import Montserrat font if not already imported elsewhere */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Base Styles */
.gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 40px;
    background-color: #ffffff; /* White background */
    font-family: 'Montserrat', sans-serif; /* Montserrat font */
    flex: 1; /* Allows the gallery to take up available space */
}

/* Gallery Item Styles */
.gallery-item {
    background-color: #f9f9f9; /* Slightly off-white for contrast */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s forwards;
}

.gallery-item:nth-child(1) {
    animation-delay: 0.2s;
}

.gallery-item:nth-child(2) {
    animation-delay: 0.4s;
}

.gallery-item:nth-child(3) {
    animation-delay: 0.6s;
}

.gallery-item:nth-child(4) {
    animation-delay: 0.8s;
}

.gallery-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.gallery-item p {
    padding: 15px;
    color: #333333;
    font-size: 16px;
    line-height: 1.5;
}

/* Hover Animations */
.gallery-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.gallery-item:hover img {
    transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .gallery-container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        padding: 30px;
    }

    .gallery-item img {
        height: 180px;
    }

    .gallery-item p {
        font-size: 15px;
    }
}

@media (max-width: 600px) {
    .gallery-container {
        grid-template-columns: 1fr;
        padding: 20px;
    }

    .gallery-item img {
        height: 150px;
    }

    .gallery-item p {
        font-size: 14px;
        padding: 10px;
    }
}

/* Entry Animations */
@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
