@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* General Styling for About Me Section */
.about-me {
    display: flex;
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center; /* Center content horizontally */
    padding: 20px;
    background: #ffffff;
    font-family: 'Montserrat', sans-serif;
    flex-wrap: wrap;
    position: relative;
}

/* Header Styling */
.about-me h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 15px; /* Add space between the title and the image */
}

/* Paragraph Styling */
.about-me p {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: #000000;
    line-height: 1.5;
    margin: 10px 0;
}

/* About Me Left Section */
.about-me-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px; /* Ensure consistent spacing below the image */
}

/* Image Styling */
.about-me-left img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 15px; /* Space between the image and any content below it */
    transition: transform 0.3s ease;
}

/* Add hover animation */
.about-me-left img:hover {
    animation: rotate 2s linear infinite, pulse 2s ease-in-out infinite;
}

/* Animation keyframes */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

/* About Me Right Section */
.about-me-right {
    max-width: 400px;
    color: #000000;
    text-align: center; /* Center the paragraph text for a balanced look */
    padding: 10px;
}

/* Mobile View Adjustments */
@media (max-width: 600px) {
    .about-me {
        padding: 10px;
    }

    .about-me-left img {
        width: 120px;
        height: 120px;
    }

    .about-me-right {
        padding-left: 0;
        margin-top: 15px;
    }
}

/* Larger Screen Adjustments */
@media (min-width: 1024px) {
    .about-me {
        flex-direction: row;
        align-items: center; /* Align content vertically in the center */
        justify-content: center; /* Center the whole section horizontally */
        padding: 40px; /* Add more padding to space the content */
    }

    .about-me-left {
        margin-right: 40px; /* Increase space between image and text */
    }

    .about-me-right {
        max-width: 600px; /* Set a larger max width for the text */
        margin-left: 40px; /* Add space between text and image for larger screens */
        text-align: left; /* Align text to the left for a professional appearance */
    }
}
