/* Container for the entire social logos section */
.socials {
    overflow: hidden;
    position: relative;
    height: 110px;
    background: #ffffff;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 100%; /* Ensure it spans the full width of its parent */
}

/* Inner wrapper that holds both sets of logos */
.socials-inner {
    display: flex;
    width: calc(100% * 2); /* Two sets of logos, side by side */
    animation: scroll 20s linear infinite;
}

.logo-container {
    flex: 0 0 100%; /* Each logo set takes up 100% of the parent */
    display: flex;
    justify-content: space-around;
}

/* Styling for each social link */
.socials a {
    display: flex;
    align-items: center;
    padding: 0 20px; /* Adjust spacing as needed */
    flex-shrink: 0; /* Prevent shrinking */
}

/* Styling for the images */
.socials img {
    height: 50px; /* Default height */
    width: auto;
    margin-right: 10px;
    animation: pulse 2s infinite;
    transition: transform 0.3s;
}

/* Hover effect for images */
.socials a:hover img {
    animation: bounce 0.6s;
}

/* Scroll animation */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%); /* Move one full set of logos */
    }
}

/* Pulse animation for logos */
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

/* Bounce animation on hover */
@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

/* Pause the scrolling animation when hovering over socials */
.socials:hover .socials-inner {
    animation-play-state: paused;
}

/* Responsive Adjustments for Screens <= 600px */
@media (max-width: 600px) {
    .socials {
        height: 50px;
    }

    .socials img {
        height: 40px;
    }

    .socials a {
        padding: 0 10px;
    }

    .socials-inner {
        animation: scroll 15s linear infinite; /* Faster scroll for smaller screens */
    }
}

/* Responsive Adjustments for Screens > 600px */
@media (min-width: 601px) {
    .socials img {
        height: 60px; /* Increased size for larger screens */
        margin-right: 20px; /* Increased spacing */
    }

    .socials a {
        padding: 0 15px; /* Adjust padding for better spacing */
    }

    /* Adjust the animation duration for larger screens for smoother scrolling */
    .socials-inner {
        animation: scroll 30s linear infinite; /* Slower scroll for larger screens */
    }
}

/* Respect User's Reduced Motion Preference */
@media (prefers-reduced-motion: reduce) {
    .socials-inner {
        animation: none;
    }
x
    .socials img {
        animation: none;
    }

    .socials a:hover img {
        animation: none;
    }
}
